

.top-bar{
  .menu-text{
    font-size: 1rem;
    color: $white ;
    font-weight: 400;
    @include breakpoint(medium up) {
      font-size: 1.2rem;
    }
  }
  ul.menu{
    li.slogan {
        //@include breakpoint(small ) {
        //  font-size: 1rem;
        //}
        //@include breakpoint(medium) {
        //    font-size: 2rem;
        //}
        //@include breakpoint(large) {
        //  font-size: 1rem;
        //}
      display: block;
      padding: 0.7rem 1rem;
      line-height: 0.8rem;
      color: $white;
    }
  }
  @include gogel-box-shadow(0px,0px,5px,0px,rgba(0,0,0,0.8));

}