.row.overlay {
    max-width: 83rem;
    margin-top: -1rem;
    &.default {
        margin: 0;
    }
}
.dummy-bg {
    background: $white;
    height: 50px;
}

//.slogan h1 {
//    font-style: italic;
//    font-weight: 300;
//    font-size: 3.3rem;
//    //padding-top: $column-gutter * 2;
//}



.banner.row {
    max-width: 79.5rem;
    position: relative;
    overflow:hidden;

}
#banner-row{
    color: $white;
    margin: 0 auto;
    background-color: change_color($gogel-violet, $alpha:0.8);
    padding: 25px;
    min-width: 75%;
    float: right;
    h1 {
        @include breakpoint(small ) {
            font-size: 2rem;
        }
        @include breakpoint(medium) {
            font-size: 3rem;
        }
        @include breakpoint(large) {
            font-size: 5rem;
        }
        color: $gogel-green;
    }

    p{
        @include breakpoint(small ) {
            font-size: 0.8rem;
            line-height: 0.3rem;
        }
        @include breakpoint(medium) {
            font-size: 1.3rem;
            line-height: 1.3rem;
        }
        @include breakpoint(large) {
            font-size: 1.8rem;
            line-height: 1.8rem;
        }
        font-weight: 400;

    }
}

.text-over-img,
.banner-img-wrap {

    @include breakpoint(small) {

        height: 23rem;
    }
    @include breakpoint(medium) {

        height: 30rem;

    }
    @include breakpoint(large) {

        height: 40rem;
    }

}

.banner-img-wrap {
    width: 100%;
    position: relative;
    opacity: 0.9;
    font-size: 20px;
    font-weight: 700;
    text-align: justify;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}


.text-over-img {
    background-color: change_color($black, $alpha:0.6);
    //padding: ($grid-column-gutter * 8) $grid-column-gutter;
    width:100%;
    .company{
        color: $white;
        //color: $gogel-green;

    }
    .slogan,
    .person {
        color: $white;
        font-size: 1.5rem;
        font-weight: 400;
    }


}