.expanded{
  padding-left: 0;
  padding-right: 0;
}

figure {
  margin:0;
  .typo3-neos-nodetypes-image{

  }
}

.columns{
  @include breakpoint(medium up) {
    padding: 0;

  }

}

.divider {
  height: $grid-column-gutter * 2;
  background-color: $gogel-violet;
}

.vertical-text {
  @include vertical-center;
  padding: 0;

}

.center-container {
  position: relative;
}

ul.image-box {
  li {
    float: left;
    list-style: none;
  }
}
.round{
  border-radius: 50%;
  min-width: 110px;
  min-height: 110px;
}
.img-shadow{
  -moz-box-shadow:    inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow:         inset 0 0 10px #000000;

  margin: ($grid-column-gutter/4);
  display: inline-block;
  @include breakpoint(small) {
    min-width: 80px;
    min-height: 80px;
  }
  @include breakpoint(medium up) {
    min-width: 140px;
    min-height: 140px;
  }

}
.single > .img-shadow{
  min-width: 220px;
  min-height: 220px;
}


.slogan {
  h1 {

    @include breakpoint(small ) {
      font-size: 2rem;
    }
    @include breakpoint(medium) {
      //@include breakpoint(portrait) {
      //  font-size: 6rem;
      //}
    }
    @include breakpoint(large) {
      font-size: 3rem;
    }
    color: $gogel-violet;
    //margin: ($grid-column-gutter / 2) ($grid-column-gutter/4);
  }
  p {
    //margin: ($grid-column-gutter) ($grid-column-gutter/4);

    color: $dark-gray;
    @include breakpoint(small ) {
      font-size: 1.2rem;
    }
    @include breakpoint(medium) {
        //font-size: 1.5rem;
    }
    @include breakpoint(large) {
      font-size: 1.3rem;
    }
  }

}


.herb-box .row{
  //margin-top: ($grid-column-gutter*2);

    margin-top: ($grid-column-gutter);
    margin-bottom: ($grid-column-gutter);

}
.herb-box {
  //margin-top: ($grid-column-gutter);
  //
  //@include breakpoint(small){
  //  margin-bottom: ($grid-column-gutter * 2);
  //}
  //@include breakpoint(medium up){
  //  margin-bottom: ($grid-column-gutter);
  //}
  //
  //@include breakpoint(medium) {
  //  min-height: 20rem;
  //}
  //@include breakpoint(large) {
  //  min-height: 25rem;
  //}
}